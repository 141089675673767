///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
/// @setup change all for new brand

// Layout
$header-height: 60px !default; // used to offset anchors, etc.

// Max widths for formatters and content containers
$container-max-width-xxl: 1800px !default;
$container-max-width-xl: 1440px !default;
$container-max-width-l: 1290px !default;
$container-max-width-m: 960px !default;
$container-max-width: 1920px !default; // recommended maximum for all commponents

// Content containers outer pad vars
// 20 / 30 / 40 / 60
$container-pad-small: 20px !default;
$container-pad-med: 30px !default;
$container-pad-l: 40px !default;
$container-pad-xl: 60px !default;

// Typography
$base-font-size: 12px !default;
$base-line-height: 1.6 !default;

// Primary grey color types - @todo audit and remove when react styles are updated
$color-primary-900: #212121;
$color-primary-800: #424242;
$color-primary-700: #616161;
$color-primary-600: #757575;
$color-primary-500: #9e9e9e;
$color-primary-400: #bdbdbd;
$color-primary-300: #e0e0e0;
$color-primary-200: #eee;
$color-primary-100: #f5f5f5;
$color-primary-50: #fafafa;
$color-grey-transparent: rgba(0, 0, 0, 0.5);
$color-grey-transparent-75: rgba(0, 0, 0, 0.75);
$color-white-transparent: rgba(255, 255, 255, 0.8);
$color-white-transparent-50: rgba(255, 255, 255, 0.5);
$color-grey-transparent-14: rgba(0, 0, 0, 0.14);

// Colors (http://sachagreif.com/sass-color-variables/)
// additional way to name colors: http://chir.ag/projects/name-that-color/

// Brand Colors
$color-slate: #eceff1;
$color-gray: #757575 !default;
$color-grey: $color-gray !default;
$color-light-black: #1c1c1c !default;
$color-light-gray: #d2d2d2 !default;
$color-light-grey: $color-light-gray !default;
$color-dark-gray: #929292 !default;
$color-dark-grey: $color-dark-gray !default;
$color-darker-grey: #a5a4a4 !default;
$color-lighter-gray: #f5f5f5 !default;
$color-darkest-grey: #595757 !default;
$color-alto-gray: #d8d8d8 !default;
$color-lighter-grey: $color-lighter-gray !default;
$color-lightest-grey-white: #f9f9f9 !default;
$color-medium-gray: #999 !default;
$color-medium-grey: $color-medium-gray !default;
$color-medium-light-gray: #e3e2e2 !default;
$color-medium-light-grey: $color-medium-light-gray !default;
$color-medium-lighter-grey: #ccc !default;

// General
$black: #000 !default;
$white: #fff !default;
$red: #d0021b !default;
$color-black: $black !default;
$color-white: $white !default;
$color-green: #d8f4e4 !default;
$color-blue: #3c5a99 !default;
$color-science-blue: #0075db !default;
$color-light-yellow: #fec600 !default;
$color-shimmer-grey: #edeef1;
$color-shimmer-grey-light: #f6f7f8;
$color-gold: #f3c200;

$color-text: $color-black !default;
$color-link: $color-black !default;
$color-link-visited: $color-black !default;
$color-link-hover: $color-grey !default;

// System colors
$color-valid: #70b000;
$color-success: $color-valid;
$color-success-shared-checkout: #0cc42f;
$color-error: #d0021b;
$color-invalid: $color-error;
$color-warning: #fbcc72;
$color-average: #dc6e10;
$color-focus: #54a6d7;
$color-placeholder: #666;
$border-color-normal: $color-light-gray;
$border-color-error: $color-error;
$icon-background: $white;
$inline-error-background: $white;
$color-error-msg: #A80000 !default;
$color-success-msg: #087443 !default;
$color-threshold-msg: #B54708 !default;

$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;

// Font / Typography related variables

// Font Family - generic
$font--serif: serif !default;
$font--sans: sans-serif !default;
$font--mono: monospace !default;

// Pretendard fonts
$font--pretendard-light: 'Pretendard Light', arial, sans-serif;
$font--pretendard-regular: 'Pretendard Regular', arial, sans-serif;
$font--pretendard-semi-bold: 'Pretendard Semi Bold', arial, sans-serif;
$font--pretendard-bold: 'Pretendard Bold', arial, sans-serif;
$font--pretendard-extra-bold: 'Pretendard Extra Bold', arial, sans-serif;
$font--pretendard-black: 'Pretendard Black', arial, sans-serif;

// Font vars for global re-use
$font--text: $font--pretendard-light;
$font--text-alt: $font--pretendard-regular;
$font--text-main: $font--pretendard-regular;
$font--heading: $font--pretendard-regular;
$font--subheading: $font--pretendard-semi-bold;
$font--label: $font--pretendard-semi-bold;
$font--button: $font--pretendard-semi-bold;
$font--select: $font--pretendard-light;

// Gnav specific vars
$gnav-max-width: $container-max-width;
$gnav-fixed-z-index: 995 !default;
$first-navbar-mobile-height: 45px !default;
$second-navbar-mobile-height: 45px !default;
$navbar-mobile-height: $first-navbar-mobile-height + $second-navbar-mobile-height !default;
$navbar-height: 95px !default;
$offerbar-height: 56px !default;
$offerbar-mobile-height: 48px !default;
$gnav-height: $navbar-height + $offerbar-height;
$gnav-mobile-height: $navbar-mobile-height + $offerbar-mobile-height;
$gnav-icon--inline: 16px !default;
$gnav-mobile-hpad--outer: 20px !default;
$gnav-mobile-link-height: 71px !default;
$gnav-content-max-height: 450px !default;
$gnav-underline-offset: 0.313rem;

// Content Blocks.
$sitewide-banner-height: 70px !default;
$sitewide-banner-mobile-height: 125px !default;
$content-block-signup-height: $sitewide-banner-height;
$content-block-signup-mobile-height: $sitewide-banner-mobile-height;

// Perlgem Styles - SASS vars
// PG styles are in /standalone
// The vars below exclude the contents of entire scss files that may not be needed for every locale.
// To include these per locale, declare the var true in the locale theme _overrides.scss

// Shared - dir is standalone/perlgem/shared/
$pg_favorites: false !default; // favorites.scss

// Checkout - dir is standalone/perlgem/checkout/
$pg_checkout_applepay: false !default; // applepay.scss
$pg_checkout_clickandcollect: false !default; // clickandcollect.scss
$pg_checkout_engraving: false !default; // engraving.scss

// Account - dir is standalone/perlgem/account/
$pg_account_loyalty: false !default; // my_points.scss
$pg_account_oab: false !default; // appointments.scss
$pg_account_replenish: false !default; // replenish.scss

//content
$content-tick-mark: '\2713' !default;
$content-cross-mark: '\00d7' !default;

// END Perlgem styles - SASS vars

// Text translations vars
$reviews_filters_title: 'Filter Reviews' !default;
$reviews_suffix: ' out of 5' !default;

$sd-grid-2-up-selector: '.skeleton-mobile-2 &, [data-num-columns-mobile="2"] &';

// Enable stylings for error message for hazmat product with restricted ingredients.
$enable-restrict-ingredient-error: false !default;
