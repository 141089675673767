///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: true) {
  @if ($value == true) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input checkbox and input radio element
///
@mixin input-types($padding: 0px) {
  left: -9999px;
  position: absolute;
  ~ label,
  ~ .label {
    display: inline-block;
    font-family: $font--text;
    font-size: get-rem(13px);
    letter-spacing: 0.3px;
    line-height: get-line-height(13px, 22px);
    padding-bottom: $padding;
    padding-#{$ldirection}: 30px;
    padding-top: $padding;
    text-transform: none;
    @include breakpoint($xlarge-up) {
      font-size: get-rem(15px);
      line-height: get-line-height(15px, 22px);
    }
    .label-content {
      background-color: transparent;
      bottom: auto;
      #{$ldirection}: auto;
      padding: 0;
      top: -1px;
    }
  }
  &:disabled ~ label,
  &:disabled ~ .label {
    color: $color-gray;
  }
}

@mixin input-checkbox($padding: 3px) {
  @include input-types($padding);
  ~ label,
  ~ .label {
    &::before {
      background-color: $color-white;
      border: 2px solid $border-color-normal;
      border-radius: 2px;
      content: '';
      cursor: pointer;
      display: inline-block;
      height: 20px;
      #{$ldirection}: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }
  }
  &:checked ~ label,
  &:checked ~ .label {
    &::before {
      background-color: $color-black;
      border-color: $color-black;
    }
    &::after {
      border-bottom: 2px solid $color-white;
      border-right: 2px solid $color-white;
      content: '';
      display: inline-block;
      height: 11px;
      left: 7px;
      position: absolute;
      top: 3px;
      transform: rotate(45deg);
      width: 6px;
    }
  }
  &:disabled ~ label,
  &:disabled ~ .label {
    &::before {
      border-color: $color-light-gray;
    }
  }
  &:disabled:checked ~ label,
  &:disabled:checked ~ .label {
    &::before {
      background-color: $color-light-gray;
    }
  }
}

@mixin input-radio($padding: 3px) {
  @include input-types($padding);
  ~ label,
  ~ .label {
    &::before {
      background-color: $color-white;
      border: 1px solid $border-color-normal;
      border-radius: 50%;
      content: '';
      display: inline-block;
      #{$ldirection}: 0;
      position: absolute;
      top: 1px;
      height: 20px;
      width: 20px;
    }
  }
  &:checked ~ label,
  &:checked ~ .label {
    &::before {
      border: 6px solid $color-black;
    }
  }
  &:disabled ~ label,
  &:disabled ~ .label {
    &::before {
      border-color: $color-light-gray;
    }
  }
}

///
/// Center Element on any Axis
/// https://css-tricks.com/snippets/sass/centering-mixin/them if it's important.
// NOTE: assumes parent element has position: relative;
@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    @include transform(translate(-50%, -50%));
    top: 50%;
    left: 50%;
  } @else if ($horizontal) {
    @include transform(translate(-50%, 0));
    left: 50%;
  } @else if ($vertical) {
    @include transform(translate(0, -50%));
    top: 50%;
  }
}

@mixin destroy-centerer() {
  @include transform(none);
  position: static;
  top: auto;
  left: auto;
}

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

// Typography helpers below

// Text helpers
// main header/title style
@mixin main-heading {
  font-family: $font--heading;
  font-size: get-rem(27px);
  line-height: get-line-height(27px, 35px);
  @include breakpoint($xlarge-up) {
    font-size: get-rem(38px);
    line-height: get-line-height(38px, 49px);
  }
}
// cart page header/title style
@mixin main-heading--medium {
  font-family: $font--subheading;
  font-size: get-rem(18px);
  font-weight: normal;
  line-height: get-line-height(18px, 24px);
  @include breakpoint($landscape-up) {
    font-size: get-rem(24px);
    line-height: get-line-height(24px, 33px);
  }
}
// secondary header/title style
@mixin secondary-heading {
  font-family: $font--subheading;
  font-size: get-rem(15px);
  letter-spacing: 0.8px;
  line-height: get-line-height(15px, 21px);
  @include breakpoint($xlarge-up) {
    font-size: get-rem(24px);
    letter-spacing: 1.2px;
    line-height: get-line-height(24px, 33px);
  }
}

@mixin secondary-heading-medium {
  font-family: $font--subheading;
  font-size: get-rem(16px);
  line-height: get-line-height(16px, 22px);
}
// secondary alternate header/title style
@mixin secondary-heading-alternate {
  font-family: $font--text;
  font-size: get-rem(16px);
  letter-spacing: 1.6px;
  line-height: get-line-height(16px, 26px);
  @include breakpoint($xlarge-up) {
    font-size: get-rem(29px);
    letter-spacing: 2.9px;
    line-height: get-line-height(29px, 46px);
  }
}
// sub header/title style
@mixin sub-heading {
  font-family: $font--subheading;
  font-size: get-rem(11px);
  letter-spacing: 1.1px;
  line-height: get-line-height(11px, 15px);
  @include breakpoint($xlarge-up) {
    font-size: get-rem(13px);
    letter-spacing: 1.3px;
    line-height: get-line-height(13px, 18px);
  }
}
// sub header/title style - medium version used in account and checkout and available for editorial blocks
@mixin sub-heading--medium {
  font-family: $font--subheading;
  font-size: get-rem(13px);
  letter-spacing: 1.3px;
  line-height: get-line-height(13px, 18px);
  @include breakpoint($xlarge-up) {
    font-size: get-rem(15px);
    letter-spacing: 1.5px;
    line-height: get-line-height(15px, 20px);
  }
}
// default styling for paragraphs/body text
@mixin body-text {
  font-family: $font--text;
  font-size: get-rem(12px);
  letter-spacing: 0.3px;
  line-height: get-line-height(12px, 22px);
  @include breakpoint($xlarge-up) {
    font-size: get-rem(13px);
    line-height: get-line-height(13px, 22px);
  }
}
// styling for 'blog' paragraphs/body text
@mixin body-text-alt {
  font-family: $font--text-alt;
  font-size: get-rem(11px);
  letter-spacing: 0.3px;
  line-height: get-line-height(11px, 20px);
  @include breakpoint($xlarge-up) {
    font-size: get-rem(15px);
    line-height: get-line-height(15px, 24px);
  }
}
// default styling for paragraphs/body text - medium version used in account and checkout and available for editorial blocks
@mixin body-text--medium {
  font-family: $font--text;
  font-size: get-rem(12px);
  letter-spacing: 0.3px;
  line-height: get-line-height(12px, 22px);
  @include breakpoint($xlarge-up) {
    font-size: get-rem(15px);
    line-height: get-line-height(15px, 24px);
  }
}
// default styling for paragraphs/body text - medium version used in cart checkout
@mixin body-text--medium-alt {
  font-family: $font--text-alt;
  font-size: get-rem(14px);
  line-height: get-line-height(14px, 22px);
}
// default styling for paragraphs/body text - large version for editorial blocks
@mixin body-text--large {
  font-family: $font--text;
  font-size: get-rem(18px);
  letter-spacing: 0.3px;
  line-height: get-line-height(18px, 28px);
  @include breakpoint($xlarge-up) {
    font-size: get-rem(20px);
    line-height: get-line-height(20px, 30px);
  }
}

@mixin body-text--small {
  font-family: $font--text-alt;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 18px);
}
// 'label' style text
@mixin label-text {
  font-family: $font--text-alt;
  font-size: get-rem(10px);
  letter-spacing: 1.3px;
  line-height: get-line-height(10px, 14px);
  text-transform: uppercase;
  @include breakpoint($xlarge-up) {
    font-size: get-rem(12px);
    letter-spacing: 1.5px;
    line-height: get-line-height(12px, 16px);
  }
}
// text link styling and animation
@mixin text-cta {
  color: $color-link;
  cursor: pointer;
  display: inline-block;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 16px);
  position: relative;
  text-decoration: none;
  &::after,
  &::before {
    content: '';
    bottom: -1px;
    height: 1px;
    left: 0;
    position: absolute;
    width: calc(100% - 0.125em);
  }
  &::after {
    background: currentColor;
    transition: width 0.25s ease-in-out;
  }
  &::before {
    background: linear-gradient(to right, currentColor 1px, transparent 1px) 100%;
    background-size: 5px 1px;
  }
  &:hover,
  &:active,
  &:focus {
    color: $color-link;
    text-decoration: none;
  }
  &:hover::after {
    left: auto;
    right: 0.125em;
    width: 0;
  }
  &:visited {
    color: $color-link;
  }
  &.text-cta--light {
    color: $color-white;
    &:hover,
    &:active,
    &:focus {
      color: $color-white;
    }
  }
}
// A variation on the text CTA
@mixin text-cta--style2 {
  @include text-cta;
  font-family: $font--button;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  @include breakpoint($xlarge-up) {
    font-size: get-rem(15px);
    line-height: get-line-height(15px, 20px);
  }
}

// Helpers
@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Buttons
// Button base - apply additional mixins or classes for color or size variants
@mixin elc-button {
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  font-family: $font--button;
  font-size: get-rem(12px);
  letter-spacing: 1.5px;
  line-height: 1;
  min-width: 160px;
  padding: 17px 16px 14px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  &:hover,
  &:active,
  &:disabled {
    text-decoration: none;
  }
}
// Smaller button
@mixin elc-button--small {
  font-size: get-rem(12px);
  font-family: $font--button;
  min-width: auto;
}
// @todo remove after react component cleanup
// temporary fixes to address presentational styles coming directly from react components
@mixin elc-button-component-overrides {
  letter-spacing: unset;
  padding: 0;
  border-radius: 0;
}

// Dark
@mixin elc-button--dark {
  background-color: $color-black;
  border-color: $color-black;
  color: $color-white;
  &:focus,
  &:hover,
  &:active {
    background-color: $color-primary-800;
    border-color: $color-primary-800;
    color: $color-white;
    transition: background-color 0.35s ease-in-out;
  }
  &:visited {
    background-color: $color-black;
    border-color: $color-black;
    color: $color-white;
  }
}

// Light
@mixin elc-button--light {
  background-color: $color-white;
  border-color: $color-black;
  color: $color-black;
  &:focus,
  &:hover,
  &:active {
    background-color: $color-slate;
    border-color: $color-black;
    color: $color-black;
    transition: background-color 0.35s ease-in-out;
  }
  &:visited {
    background-color: $color-white;
    border-color: $color-black;
    color: $color-black;
  }
}

// Inverted
@mixin elc-button--inverted {
  background-color: $color-white;
  border-color: $color-white;
  color: $color-black;
  &:visited {
    color: $color-black;
  }
  &:focus,
  &:hover,
  &:active {
    background-color: $color-slate;
    border-color: $color-white;
    transition: background-color 0.35s ease-in-out;
  }
}

///
/// Fluid Images for responsive designs
///
@mixin fluid-image {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

// Outline style on focused elements for keyboard accessibility
@mixin outline {
  outline-style: auto;
  outline-width: 1px;
  outline-color: Highlight; // Firefox
  outline-color: -webkit-focus-ring-color; // WebKit based browsers
}
/* SelectBox Dropdown control */
@mixin selectBox {
  font-size: get-rem(12px);
  text-decoration: none;
  text-align: left;
  min-width: 150px;
  position: relative;
  border: 1px solid $color-light-gray;
  border-radius: 0;
  color: $color-dark-gray;
  outline: none;
  vertical-align: middle;
  display: inline-block;
  height: 40px;
  line-height: 37px;
  background: $color-white;
  cursor: pointer;
}
/* SelectBox Options */
@mixin selectBox-options {
  font-size: get-rem(12px);
  text-decoration: none;
  text-align: left;
  list-style: none;
  display: block;
  padding: 10px 0;
  margin: 0;
  overflow: auto;
  cursor: pointer;
}

// Tooltips
@mixin tooltip-icon {
  background-color: $color-black;
  border-radius: 50%;
  color: $color-white;
  content: '\0069';
  display: inline-block;
  font-family: $font--sans;
  font-size: get-rem(12px);
  height: get-rem(18px);
  line-height: get-line-height(12px, 18px);
  text-align: center;
  width: get-rem(18px);
}

@mixin tooltip {
  @include body-text--medium;
  margin-bottom: 30px;
  position: relative;
  text-decoration: underline;
  &:hover,
  &:focus {
    .tooltiptext-over {
      bottom: 100%;
      opacity: 1;
      visibility: visible;
    }
  }
  &::before {
    @include tooltip-icon;
    margin-#{$rdirection}: 8px;
  }
  .tooltiptext-over {
    background-color: $color-black;
    color: $color-white;
    #{$ldirection}: 0;
    margin-bottom: 6px;
    max-width: 480px;
    opacity: 0;
    padding: 15px 15px 10px;
    position: absolute;
    transition: opacity 250ms;
    transition-delay: 500ms;
    visibility: hidden;
    width: 100%;
    z-index: 100;
    &::after {
      @include triangle(10px, $color-black, 'down');
      bottom: -10px;
      content: '';
      #{$ldirection}: 4px;
      position: absolute;
    }
  }
  a {
    color: $color-white;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

// Close link
@mixin close-link {
  background-image: url('#{$base-theme-path}img/icons/src/close.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 25%;
  cursor: pointer;
  display: block;
  height: get-rem(56px);
  text-indent: -999rem;
  width: get-rem(56px);
  &:hover,
  &:focus {
    filter: contrast(10%);
    text-decoration: none;
  }
}

// Container related width rules
@mixin container-max-width {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $container-max-width;
  width: 100%;
}

@mixin container-max-width-full {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: 100%;
  width: 100%;
}

@mixin container-max-width-xxl {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $container-max-width-xxl;
  width: 100%;
}

@mixin container-max-width-xl {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $container-max-width-xl;
  width: 100%;
}

@mixin container-max-width-l {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $container-max-width-l;
  width: 100%;
}

@mixin container-max-width-m {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $container-max-width-m;
  width: 100%;
}

// Container related verical margin rules
// 768 $medium-up / 1024 $landscape-up / 1440 $xlarge-up
// Values below are selectable via the CMS
// X-Large: 96px / 70px / 50px / 40px (PC to Mobile range)
// Large: 60px / 50px / 35px / 20px (PC to Mobile range)
// Small: 40px / 35px / 25px / 20px (PC to Mobile range)
@mixin container-vertical-rules($dir: top) {
  .container-vertical-#{$dir}-margin {
    &--xlarge {
      margin-#{$dir}: 40px;
      @include breakpoint($medium-up) {
        margin-#{$dir}: 50px;
      }
      @include breakpoint($landscape-up) {
        margin-#{$dir}: 70px;
      }
      @include breakpoint($xlarge-up) {
        margin-#{$dir}: 96px;
      }
    }
    &--large {
      margin-#{$dir}: 20px;
      @include breakpoint($medium-up) {
        margin-#{$dir}: 35px;
      }
      @include breakpoint($landscape-up) {
        margin-#{$dir}: 50px;
      }
      @include breakpoint($xlarge-up) {
        margin-#{$dir}: 60px;
      }
    }
    &--small {
      margin-#{$dir}: 20px;
      @include breakpoint($medium-up) {
        margin-#{$dir}: 25px;
      }
      @include breakpoint($landscape-up) {
        margin-#{$dir}: 35px;
      }
      @include breakpoint($xlarge-up) {
        margin-#{$dir}: 40px;
      }
    }
    &--extra-large {
      margin-#{$dir}: 40px;
      @include breakpoint($landscape-up) {
        margin-#{$dir}: 80px;
      }
      @include breakpoint($xlarge-up) {
        margin-#{$dir}: 100px;
      }
    }
  }
}

///
/// Triangle - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

// Loaders
@mixin loader($color: $black) {
  $loader-pos: 9999px;
  $loader-dot-spacing: 15px;
  $loader-dot1: $loader-pos - $loader-dot-spacing;
  $loader-dot2: $loader-pos;
  $loader-dot3: $loader-pos + $loader-dot-spacing;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: $color;
  color: $color;
  display: none;
  position: relative;
  right: $loader-pos;
  margin: auto;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
  &::before,
  &::after {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: $color;
    color: $color;
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }
  &::after {
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }
  @keyframes dot-pulse-before {
    0% {
      box-shadow: $loader-dot1 0 0 1px;
    }
    30% {
      box-shadow: $loader-dot1 0 0 5px;
    }
    60%,
    100% {
      box-shadow: $loader-dot1 0 0 1px;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: $loader-dot2 0 0 1px;
    }
    30% {
      box-shadow: $loader-dot2 0 0 5px;
    }
    60%,
    100% {
      box-shadow: $loader-dot2 0 0 1px;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: $loader-dot3 0 0 1px;
    }
    30% {
      box-shadow: $loader-dot3 0 0 5px;
    }
    60%,
    100% {
      box-shadow: $loader-dot3 0 0 1px;
    }
  }
}

@mixin ternary($condition, $property, $true-value, $false-value) {
  @if ($condition) {
    #{$property}: $true-value;
  } @else {
    #{$property}: $false-value;
  }
}

@mixin spinner($width: 36px, $height: 36px) {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: $width;
  height: $height;
  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .animated-group {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
}
