@import '../theme-bootstrap';
@import '../../../../../all/themes/elc_base/scss/standalone/shared/animations';

// Skeleton Product Full
.sd-product-spp {
  .sd-full-skeleton {
    .skeleton-full {
      display: inline-block;
      padding: 0 0 80px;
      width: 100%;
    }
    .elc-skeleton-region {
      &--left-column {
        float: $ldirection;
        line-height: 0;
        position: relative;
        width: 100%;
        @include breakpoint($landscape-up) {
          line-height: inherit;
          padding-#{$ldirection}: 95px;
          padding-top: 40px;
          width: 53%;
          &::before {
            @include shimmer(1.5s, ease-in-out);
            content: '';
            height: 70px;
            #{$ldirection}: 0;
            position: absolute;
            width: 70px;
          }
        }
        .elc-skeleton-feature {
          &--desktopWrapper {
            width: 100%;
            display: block;
          }
          &--mobileWrapper {
            display: none;
          }
          &--carousel {
            max-width: 472px;
            margin: 0 auto;
          }
        }
      }
      &--right-column {
        float: $ldirection;
        padding: 0 20px;
        @include breakpoint($landscape-up) {
          padding: 45px 30px 0;
          width: 47%;
        }
        .elc-skeleton-feature {
          &--displayName h1 {
            font-family: $font--pretendard-semi-bold;
            font-size: 21px;
            font-weight: normal;
            line-height: get-line-height(21px, 25px);
            margin: 0 0 10px;
            text-transform: capitalize;
            @include breakpoint($landscape-up) {
              font-size: 24px;
              line-height: get-line-height(24px, 28px);
            }
          }
          &--description {
            color: $color-black;
            font-family: $font--text-main;
            font-size: 16px;
            height: 46px;
            line-height: get-line-height(16px, 23px);
            margin-top: 10px;
            overflow: hidden;
            text-transform: initial;
            @include breakpoint($landscape-up) {
              font-size: 18px;
              line-height: get-line-height(18px, 25px);
            }
            h2 {
              display: none;
            }
          }
          &--ratingAndReviews {
            color: $color-black;
            font-family: $font--text-main;
            font-size: 16px;
            font-weight: normal;
            height: 25px;
            line-height: get-line-height(16px, 23px);
            margin-top: 15px;
            text-decoration: underline;
            text-transform: capitalize;
            width: 100%;
          }
          &--subDisplayName {
            @include shimmer(1.5s, ease-in-out);
            height: 30px;
            margin-top: 10px;
            width: 100px;
          }
          &--price,
          &--installmentsPrice,
          &--sizePicker {
            @include shimmer(1.5s, ease-in-out);
            height: 25px;
            margin-top: 15px;
            width: 200px;
          }
          &--installmentsPrice {
            margin-top: 10px;
            width: 300px;
          }
          &--addToBag {
            @include elc-button--dark;
            @include elc-button;
            font-size: 14px;
            height: 46px;
            margin-top: 10px;
            padding: 15px 16px;
            width: 100%;
          }
          &--overview,
          &--ingredients {
            @include shimmer(1.5s, ease-in-out);
            height: 40px;
            margin-top: 20px;
            width: 100%;
          }
        }
      }
    }
  }
  .elc-image-slider-images-wrapper {
    position: relative;
  }
  .elc-product-badge {
    margin: 0;
    text-transform: uppercase;
    #{$rdirection}: 30px;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $color-black;
    color: $color-black;
    font-family: $font--text;
    font-size: 7px;
    height: 40px;
    line-height: 1.4;
    position: absolute;
    text-align: center;
    top: 15px;
    width: 40px;
    display: flex;
    justify-content: center;
    overflow-wrap: anywhere;
    @include breakpoint($landscape-up) {
      font-size: 12px;
      line-height: 2.1;
      width: 65px;
      height: 65px;
      #{$rdirection}: 15px;
    }
  }
}
