///
/// @file global/_functions.scss
///
/// \brief Functions
///
/// Useful functions to help us calculate various values
/// (Credit to Zurb Foundation, http://foundation.zurb.com/docs)
///

// http://stackoverflow.com/a/11752227
@function compact(
  $var-1,
  $var-2: false,
  $var-3: false,
  $var-4: false,
  $var-5: false,
  $var-6: false,
  $var-7: false,
  $var-8: false,
  $var-9: false,
  $var-10: false
) {
  $full: $var-1;
  $vars: $var-2, $var-3, $var-4, $var-5, $var-6, $var-7, $var-8, $var-9, $var-10;

  @each $var in $vars {
    @if $var {
      $full: $full, $var;
    }
  }
  @return $full;
}

// RANGES
// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range, 1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

// rem function to convert pixels to rem
@function get-rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

// line height function to generate unitless numbers vs the px values in the styleguide
@function get-line-height($font-size, $line-height) {
  @return $line-height / $font-size;
}
